var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-buyers animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.buyersTableColumns,"rows":_vm.buyersTableData,"totalRows":_vm.buyersTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.openBuyerDetails},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[(props.row.isActive)?_c('button',{staticClass:"btn danger",attrs:{"title":"Lock"},on:{"click":function($event){return _vm.updateBuyersStatus(props.row.id, props.row.isActive)}}},[_c('i',{staticClass:"fas fa-lock"})]):_c('button',{staticClass:"btn success",attrs:{"title":"Unlock"},on:{"click":function($event){return _vm.updateBuyersStatus(props.row.id, props.row.isActive)}}},[_c('i',{staticClass:"fas fa-key"})])]):_vm._e(),(props.column.field == 'isActive')?_c('span',[(props.row.isActive)?_c('i',{staticClass:"fas fa-unlock unlocked"}):_c('i',{staticClass:"fas fa-lock locked"})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }