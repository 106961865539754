<template>
  <div class="manage-buyers animatedBox">
    <div class="container fluid">
      <div class="card">
        <!-- Table -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="buyersTableColumns"
          :rows="buyersTableData"
          :totalRows="buyersTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="openBuyerDetails"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <!-- <button class="btn main mr-2" @click="toAddBuyer">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button> -->
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                v-if="props.row.isActive"
                class="btn danger"
                title="Lock"
                @click="updateBuyersStatus(props.row.id, props.row.isActive)"
              >
                <i class="fas fa-lock"></i>
              </button>
              <button
                v-else
                class="btn success"
                title="Unlock"
                @click="updateBuyersStatus(props.row.id, props.row.isActive)"
              >
                <i class="fas fa-key"></i>
              </button>
            </span>
            <span v-if="props.column.field == 'isActive'">
              <i v-if="props.row.isActive" class="fas fa-unlock unlocked"></i>
              <i v-else class="fas fa-lock locked"></i>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-buyers",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      buyersTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact",
          field: (row) => this.$getUserPhoneNumber(row.user)
        },
        {
          label: "Account Status",
          field: "isActive",
          tdClass: "text-center",
          width: "100px"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      buyersTableData: [],
      buyersTablePagination: {},
      isFilterActive: false,
      filter: {
        referenceCode: "",
        "title[partial]": "",
        category: "",
        furnishingType: "",
        purpose: ""
      }
    };
  },
  mounted() {
    this.getAllBuyers({ limit: this.tableParams.perPage });
  },
  methods: {
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllBuyers();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBuyers();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openBuyerDetails(event) {
      /* this.isPropertyDetailShown = true;
      this.isPropertyDetailLoading = true;
      this.getPropertyDetail(event.row.id).then(() => {
        this.isPropertyDetailLoading = false;
      }); */
    },

    // ============================= API Related ===============================
    async getAllBuyers(queries = {}) {
      this.$store.commit("setIsLoading", true);
      let data = await this.$store.dispatch("manageBuyer/getAllManageBuyer", {
        ...queries,
        page: this.tableParams.page,
        limit: this.tableParams.perPage
      });

      this.buyersTableData = this._.cloneDeep(data.data);
      this.buyersTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    async updateBuyersStatus(id, isActive) {
      const c = confirm(
        `Are you sure to ${isActive ? "lock" : "un-lock"} this account?`
      );

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          let data = await this.$store.dispatch(
            "manageBuyer/updateBuyersStatus",
            {
              id: id,
              data: {
                isActive: !isActive
              }
            }
          );

          this.$store.commit("setIsLoading", false);
          this.getAllBuyers();
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          this.$store.commit("setIsLoading", false);
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-buyers {
  i.locked {
    color: $color-danger;
    font-size: 16px;
  }
  i.unlocked {
    color: $color-success;
    font-size: 16px;
  }
}
</style>
